<template>
  <div class="text">
    <!-- pic1E3FC0F9EEF2EF885C21AA97F587F620.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/join-a-nexus-force-faction.jpg">
    <p>
      One tiny shard of Pure Imagination remains in LEGO Universe-- and the mighty Nexus Force has vowed to defend it at all costs!
    </p>
    <p>
      Led by powerful minifigures, these four Nexus Force factions bring special strengths to the battle to save imagination:
    </p>
    <p>
      <b>Assembly</b> - Guided by construction specialist Doc Overbuild, the Assembly battles back the Maelstrom with creative building skills!
    </p>
    <p>
      <b>The Sentinels</b> - Legendary warrior Duke Exeter commands the Sentinels to fight the dark menace with awesome force!
    </p>
    <p>
      <b>Venture League</b> - Space pirate Hael Storm's Venture League explorers seek answers and solve mysteries in every corner of the Universe!
    </p>
    <p>
      <b>Paradox</b> - Ninja warrior Vanda Darkflame instructs her Paradox disciples in the secret art of turning chaos energy against the Maelstrom!
    </p>
    <p>
      As you adventure into LEGO Universe, you can join a faction to express your unique creative style! Customize your minifigure with the coolest LEGO gear as you rise in your faction's ranks!
    </p>
    <p>
      Who do you want to be in LEGO Universe?
    </p>
  </div>
</template>
